import React from 'react'
import { XCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase.config';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ showModal, setShowModal, setShowRegisterModal }) => {


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const nav= useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        await signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setLoading(false)
                setShowModal(false)
                nav('/')
                // alert("Login Success")
            })
            .catch((error) => {
                const errorMessage = error.message;
                alert(errorMessage)
                setLoading(false)
            })
    }
    return (
        <>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-sm">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className='cursor-pointer' onClick={() => setShowModal(false)}>
                                    <XCircleIcon color='red' className='w-5 m-5 -mb-3 h-5' />
                                </div>

                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">

                                    <h3 className="text-3xl font-semibold">
                                        Sign In
                                    </h3>

                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className='mb-5'>
                                        <p>Email</p>
                                        <input 
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder='johndoe@gmail.com' 
                                        class="bg-gray-50 border w-72 border-gray-300  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block  p-2.5" />
                                    </div>
                                    <div className='mb-10'>
                                        <p>password</p>
                                        <input
                                            onChange={(e) => setPassword(e.target.value)}
                                            type='password'
                                            placeholder='johndoe@gmail.com' class="bg-gray-50 border w-72 border-gray-300  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block  p-2.5" />
                                    </div>


                                    <div>
                                        <button onClick={onSubmit} type="submit" class="w-full text-white bg-[#2b14dd] hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center">{loading?"Loading...":"Sign in"}</button>
                                        <p onClick={() => {
                                            setShowModal(false);
                                            setShowRegisterModal(true)
                                        }} className='text-xs font-bold mt-3 cursor-pointer hover:underline'>Don't have an account</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}

export default LoginModal