import React, { useEffect, useState } from 'react';
import Son from "../assets/seb.jpg";
import Son1 from "../assets/logo.png";
import Footer from '../components/Footer';
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase.config'

const AboutUs = ({ setShowModal }) => {
    const [user, setUser] = useState(null); // Store the authenticated user

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user); // Store the authenticated user
            } else {
                setUser(null); // No user is authenticated
            }
        });

        return () => unsubscribe();
    }, [user]);

  return (
    <div className=' text-center text-white  section hero" id="home" aria-label="hero'
    style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >

        <div className='p-14'>

            <div className='justify-center items-center mt-10 flex'>
            <img
             src={Son1}
             className='w-96 self-center'
            alt="" />
            </div>
            {/* <h1 className="text-white font-bold lg:text-6xl text-4xl text-center mb-10">
        Athlete Finders</h1> */}
      <p>Athlete Finders is a company that scouts for potential players online. We use our proprietary technology to identify talented athletes from all over the world. We then connect these athletes with coaches and scouts from professional and collegiate teams.</p>
      <p>We are passionate about helping athletes achieve their dreams of playing at the highest level. We believe that everyone deserves the opportunity to be seen, and we are committed to providing our athletes with the exposure they need to succeed.</p>
      <p>If you are an athlete who is looking to be scouted, we encourage you to create a profile on our website. We will review your profile and, if we see potential, we will reach out to you to learn more.</p>

      <h3>How our scouting process works:</h3>
      <ul>
        <li>We use a variety of methods to identify talented athletes, including social media, online recruiting services, and our own proprietary technology.</li>
        <li>Once we have identified a potential athlete, we will review their profile and video footage to learn more about their skills and abilities.</li>
        <li>If we believe that the athlete has the potential to play at the next level, we will reach out to them to learn more about their goals and aspirations.</li>
        <li>If the athlete is a good fit for our services, we will work with them to create a personalized scouting plan.</li>
        <li>We will then promote the athlete to our network of coaches and scouts, and we will help them to connect with the right people to take their career to the next level.</li>
      </ul>

      <h3>Why choose Athlete Finders?</h3>
      <ul>
        <li>We have a proven track record of success. We have helped hundreds of athletes to achieve their dreams of playing at the next level.</li>
        <li>We have a global network of coaches and scouts. We can connect you with the right people to take your career to the next level.</li>
        <li>We are committed to providing our athletes with the best possible support. We will work with you to create a personalized scouting plan and we will help you to achieve your goals.</li>
      </ul>

      <p>If you are an athlete who is looking to be scouted, we encourage you to create a profile on our website today. We are committed to helping you achieve your dreams of playing at the highest level.</p>
    </div>
   
      <Footer user={user} setShowModal={setShowModal}/>

    </div>
  );
};

export default AboutUs;
