import React, { useRef, useState } from 'react'
import { BsFillImageFill, BsFillCameraVideoFill } from 'react-icons/bs';
import { addDoc, collection, doc, serverTimestamp } from "firebase/firestore";
import { Storage, auth, db } from '../firebase.config';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from 'uuid'



const SendMessage = ({roomId}) => {
    const [value, setValue] = useState("");


    const fileInputRef = useRef(null);
    const videoInputRef = useRef(null)

    const openFilePicker = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const uploadFileToStorage = async (file) => {
        const fileRef = ref(Storage, `mediafiles/${v4()}`);
        uploadBytes(fileRef, file)
    };

    const handleFileSelect = async (event) => {
        const selectedFile = event.target.files[0];

        // Access the file size in bytes
        const fileSizeInBytes = selectedFile.size;

        // Convert file size to kilobytes (KB) or megabytes (MB) for better readability
        const fileSizeInKB = fileSizeInBytes / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;

        if (fileSizeInMB.toFixed(2) > 10) {
            alert("File size is too large!")
        } else {
            if (selectedFile.type.startsWith('image/')) {
                const fileRef = ref(Storage, `mediafiles/${v4()}`);
                uploadBytes(fileRef, selectedFile)
                    .then((snapshot) => {
                        // After successful upload, get the download URL
                        getDownloadURL(fileRef)
                            .then(async(downloadURL) => {
                                const val = doc(db, 'rooms', roomId)
                                const collectionVal = collection(val, 'chats')
                                await addDoc(collectionVal, { text: value || null, timeSent: serverTimestamp(), senderId: auth.currentUser?.uid,mediaType:'image',url:downloadURL,media:true })
                                    .then((res) => {
                                        setValue('')
                                        console.log(res)
                                    })
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    })
                    .catch(() => {
                        alert('small errors');
                    });
            } else if (selectedFile.type.startsWith('video/')) {
                const fileRef = ref(Storage, `mediafiles/${v4()}`);
                uploadBytes(fileRef, selectedFile)
                    .then((snapshot) => {
                        // After successful upload, get the download URL
                        getDownloadURL(fileRef)
                            .then(async(downloadURL) => {
                                const val = doc(db, 'rooms', roomId)
                                const collectionVal = collection(val, 'chats')
                                await addDoc(collectionVal, { text: value || null, timeSent: serverTimestamp(), senderId: auth.currentUser?.uid,mediaType:'video',url:downloadURL,media:true })
                                    .then((res) => {
                                        setValue('')
                                        console.log(res)
                                    })
                            })
                            .catch((error) => {
                               alert("An error occured!")
                            });
                    })
                    .catch(() => {
                        alert('An error occured!');
                    });
            } else {
                alert("We only accept video and image file")
            }
        }
    };



    const openVideoPicker = () => {
        if (videoInputRef.current) {
            videoInputRef.current.click();
        }
    };

    const SendMessage = async (e) => {
        e.preventDefault();

        if (value.length<1){
            alert("input field can't be empty")
        }

        const val = doc(db, 'rooms', roomId)
        const collectionVal = collection(val, 'chats')
        await addDoc(collectionVal, { text: value, timeSent: serverTimestamp(), senderId: auth.currentUser?.uid,media:false })
            .then((res) => {
                setValue('')
                console.log(res)
            })
    }
    return (
        <div className="bg-[#333] fixed bottom-0 w-full py-10 shadow-lg">
            <form className="px-2 containerWrap flex">
                <input
                    ref={fileInputRef}
                    type="image"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                />
                <input
                    ref={videoInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                />
                <input
                    placeholder='Type Message....'
                    value={value}
                    onChange={e => setValue(e.target.value)} className="input w-full focus:outline-none bg-gray-100 rounded-md ml-4" type="text" />
                <div className='w-3' />
                {value.length === 0 && <div className='flex px-2'>
                    <BsFillCameraVideoFill onClick={openVideoPicker} color='#fff' className='w-10 h-10 px-2 cursor-pointer' />
                    <BsFillImageFill 
                    onClick={openVideoPicker}
                    // onClick={openFilePicker}
                     color='#fff' className='w-10 h-10 px-2 cursor-pointer' />
                </div>}
                <button disabled={!value} onClick={SendMessage} className="w-auto bg-blue-700 text-white rounded-md px-5 text-sm">Send</button>
            </form>
        </div>
    )
}

export default SendMessage