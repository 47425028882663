import React from 'react'
import SingleMessage from './SingleMessage'

const ChatBox = ({data}) => {

    return (
        <div className='py-44 pt-24 containerWrap'>
            {data?.map((message, index) => {
                return (
                    <SingleMessage key={message.id} message={message}/>
                )
            })}
        </div>
    )
}

export default ChatBox