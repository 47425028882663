import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBPCoCvQPY1Nl8iIp_38V_OQ_Jydx1iU5M",
    authDomain: "scout-ce9e9.firebaseapp.com",
    projectId: "scout-ce9e9",
    storageBucket: "scout-ce9e9.appspot.com",
    messagingSenderId: "476875865841",
    appId: "1:476875865841:web:2747c4609a869cc2c13350",
    measurementId: "G-CVLM0VNNTW"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app)
export const Storage = getStorage(app)
export default app;