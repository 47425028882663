import React, { useState } from 'react';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { PhoneIcon, ArrowSmRightIcon } from '@heroicons/react/outline';
import { ChipIcon, SupportIcon, MailIcon, ChatIcon } from '@heroicons/react/solid'
import { Link, useNavigate } from 'react-router-dom';
import supportImg from '../assets/bb.jpg'
import { auth, db } from '../firebase.config';

const Support = ({ setShowModal }) => {

    const nav = useNavigate();
    const [laoding, setLoading] = useState(false)

    return (
        <div name='support' className='w-full mt-24 mb-32'>
            <div className='w-full h-[700px] bg-gray-900/90 absolute'>
                <img className='w-full h-full object-cover mix-blend-overlay' src={supportImg} alt="/" />
            </div>

            <div className='max-w-[1240px] mx-auto text-white relative'>
                <div className='px-4 py-12'>
                    {/* <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'>Support</h2> */}
                    <h3 className='text-5xl font-bold py-6 text-center'>Talk To Us</h3>
                    <p className='py-4 text-3xl text-slate-300'>If you have any question please contact us from the button below!</p>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
                  
                    {/* <div className='bg-white rounded-xl shadow-2xl'>
                        <div className='p-8'>
                            <MailIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]' />
                            <h3 className='font-bold text-2xl my-6'>Drop an Email</h3>
                            <p className='text-gray-600 text-xl'>You can also drop us an email, which will be responded to as soon as possible</p>
                        </div>
                        <div className='bg-slate-100 pl-8 py-4 absolute bottom-0 cursor-pointer'>
                            <p className='flex items-center text-indigo-600'>Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></p>
                        </div>
                    </div> */}
                    <div className='bg-white lg:w-[200%] rounded-xl shadow-2xl'>
                        <div className='p-8'>
                            <ChatIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]' />
                            <h3 className='font-bold text-2xl my-6'>Chat With Us</h3>
                            <p className='text-gray-600 text-xl mb-10'>Chat with us here on our website</p>
                        </div>
                        <div
                            onClick={async () => {
                                if (auth.currentUser) {
                                    setLoading(true)
                                    const docRef = doc(db, "rooms", auth.currentUser?.uid);
                                    const docSnap = await getDoc(docRef);
                                    if (docSnap.exists()) {
                                        nav(`chat/${docSnap.id}`, { state: { id: docSnap.id } })
                                    } else {
                                        await setDoc(doc(db, "rooms", auth.currentUser?.uid), {
                                            time: new Date(),
                                            scouteeId: auth.currentUser?.uid,
                                            cemail: auth.currentUser.email,
                                            admin: '7GkoMZyFL5R2vrGxkDExy6JOMZP2'
                                        }).then(() => {
                                            setLoading(false)
                                            nav(`chat/${auth.currentUser?.uid }`, { state: { id: auth.currentUser?.uid, admin: '7GkoMZyFL5R2vrGxkDExy6JOMZP2' } })
                                        }).catch(() => {
                                            setLoading(false)
                                            alert("An error occured!")
                                        })
                                    }
                                } else {
                                    setShowModal(true)
                                }
                            }}ZZ
                            className='bg-slate-100 pl-8 py-4 absolute bottom-0 cursor-pointer'>
                            <p className='flex items-center text-indigo-600'>{laoding ? 'Please wait...' : "Talk To Us"} <ArrowSmRightIcon className='w-5 ml-2' /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
