import React, { useEffect, useRef, useState } from 'react'
import ChatBox from './ChatBox'
import SendMessage from './SendMessage'
import { useLocation, useParams } from 'react-router-dom'
import { auth, db } from '../firebase.config'
import { collection, doc, getDocs, onSnapshot, orderBy, query } from 'firebase/firestore'
import Son from "../assets/seb.jpg";

const ChatRoom = () => {


  const location = useLocation();

  const { id } = useParams();



  const roomId = location?.state?.id;
  const user = location?.state?.user

  const messagesEndRef = useRef();
  const [messages, setMassages] = useState([]);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
  };

  useEffect(scrollToBottom, [messages])

  useEffect(() => {
      const val = doc(db, "rooms", id);
      const collectionVal = collection(val, "chats");

      const q=query(collectionVal,orderBy('timeSent','asc'))

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newMessages = [];
        querySnapshot.forEach((doc) => {
          newMessages.push({ ...doc.data(), id: doc.id });
        });
        setMassages(newMessages);
      });

      return () => unsubscribe();
  }, [auth,roomId,id]);


  return (
    <div 
            style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'repeat', }}
    className='h-[100%] w-[100%] bg-blue-100'
    >
       <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url(${Son})`, // Set the path to your background image
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div>
      
      <ChatBox data={messages} />
      <SendMessage roomId={id} />
    </div>
  )
}

export default ChatRoom