import { useState } from 'react';
import About from './components/About';
import AllInOne from './components/AllInOne';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar'
import Support from './components/Support'
import LoginModal from './components/LoginModal';
import RegisterModal from './components/RegisterModal';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Home from './screens/Home';
import { useEffect } from 'react';
import { getDoc, doc } from "firebase/firestore";
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase.config';
import EditProfile from './screens/EditProfile';
import Privacy from './components/Privacy';
import ChatRoom from './components/ChatRoom';
import AboutUs from './screens/AboutUs';
import TNC from './components/TNC';


function App() {

  const [showModal, setShowModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const navigate = useNavigate();

  const [user, setUser] = useState(null); // Store the authenticated user

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
              setUser(user); // Store the authenticated user
          } else {
              setUser(null); // No user is authenticated
          }
      });

      return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        const userRef = doc(db, "Users", auth.currentUser?.uid)
        getDoc(userRef)
          .then((query) => {
            if (query.exists) {
              return
            } else {
              navigate({ to: '/edit_profile' })
            }
          })
      } else {
        return 
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <Navbar
        showModal={showModal}
        setShowModal={setShowModal}
        user={user}
      />
      <Routes>
        <Route path='/' element={<Home showModal={showModal} setShowModal={setShowModal}/>} />
        <Route path='/privacy' element={<Privacy showModal={showModal} setShowModal={setShowModal}/>} />
        <Route path='/TNC' element={<TNC showModal={showModal} setShowModal={setShowModal}/>} />
        <Route path='/About' element={<AboutUs showModal={showModal} setShowModal={setShowModal} />} />
        <Route path='/chat/:id' element={<ChatRoom/>} />
        <Route path='/edit_profile' element={<EditProfile />} />
      </Routes>
      <RegisterModal showModal={showModal} setShowModal={setShowModal} showRegisterModal={showRegisterModal} setShowRegisterModal={setShowRegisterModal} />
      <LoginModal showModal={showModal} setShowModal={setShowModal} showRegisterModal={showRegisterModal} setShowRegisterModal={setShowRegisterModal} />
    </>
  );
}

export default App;
