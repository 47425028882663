import React, { useEffect, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase.config'
import Footer from './Footer';

const TNC = ({ setShowModal }) => {
    const [user, setUser] = useState(null); // Store the authenticated user

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user); // Store the authenticated user
            } else {
                setUser(null); // No user is authenticated
            }
        });

        return () => unsubscribe();
    }, [user]);

    return (
        <div className='py-32'>
            <div className='m-4'>
                <h1 className='font-bold text-2xl p-10'>Terms And Conditions</h1>
                <p className='text-gray-600 p-10 font-semibold'>
                Agreement
If you’re using the Athlete Finders (www.athletefinders.com) services, you’re entering into a legal agreement automatically which includes that you agree to all of Athlete Finders terms. In addition, you also agree to Athlete Finders Privacy Policy, with regards to how we collect, use, share, and store your personal information.

During the sign-up page, by clicking the ‘continue’ button (be aware the button ‘continue’ may change due to improvement via colour, text or even the name could change to ‘submit’) and ticking the box to agree with the User Agreement, this concludes that you’ve agreed with the terms and registering, accessing or using our services (and in the future for our related mobile apps) developer platforms, whether it’s the free or subscription services, or any content or information provided as part of these services. You’re entering into a legally binding agreement (even if you are using our Services on behalf of a company).

If you don’t agree with the User Agreement, Privacy Policy or any other agreement within our platform, we advise that you don’t tick both of ‘terms of service’ box and click the ‘continue’ button during the sign-up page which the outcome is that you won’t get access to Athlete Finders services. This includes all software, functions and current and future application on multiple devices (mobile and desktop etc) or any of Athlete Finders services.

All users who agree and register are recognised as “members” whereas those who are unregistered users are recognised as “visitors’.

All users agree to check all our notification via the online platform when logging in the Athlete Finders service or email for any updates with the User Agreement, policy related to data or any changes to all agreement. It’s the user’s responsibility to check before continuing to use the service. By continuing to use the service, you’re agreeing to all of Athlete Finders terms and agreement regardless of any changes or updates. Please see section 5 on this User Agreement; Notices and Service Messages which will give more information about updates and changes.

2.	Eligibility
Within Athlete Finders User Agreement, here are the lists that you as a user who agrees, and promises to cooperate:

You must be at least the “minimum age” according to Athlete Finders standard which is defined in the following;

a) 14 years old if you’re located in Australia, Canada, Germany, Spain, South Korea and United States
b) 16 years old if you’re located in Netherlands
c) 18 years old if you’re located in China
d) 13 years old if you’re located in all countries (this does not include the countries listed above a, b, and c)
e) If for a specific country where the law does not correlate with our standard which is listed from above (a, b, c & d), due to some changes or updates, then we agree with the law which requires that you be older to the location’s law where user Is located for Athlete Finders to lawfully provide the services to the user then the “minimum age” is such older age.
If you’re a parent would like to use Athlete Finders behalf of your child (as the child is under the required age), then this will be ok considering that you (parents/guardian) agree that you are responsible and are fully aware of you or your child is using the desktop or in the future for our mob application which involves uploading image, videos, personal contact and connecting with other users.
You agree that you will provide your real name, information on age, date of birth, accurate information about yourself (company, club or any organisation that you currently represent etc), and that you don’t already have an existing account. You will only create one account within Athlete Finders services which you can’t create an account for anyone else.

3.	Membership
You agree to not share your password with anyone and you keep it confidential by following Athlete Finders terms. Regarding to using the subscription services which gives you access to additional features and services, you agree that you will not share your account with another user or non-user for the benefit of the subscription services but more importantly, you can’t share the account with anyone at all. Overall, you agree that you’re accountable for anything that happens through your account unless we close it due to misconduct of use or if you decide to close the account. Please see the section 16 and 17 of this User Agreement for information on Cooperation.

Athlete Finders is not liable for any fraud or misused however it is the user’s responsibility to protect its information and will be accountable. We have connected all subscription to take payment via PayPal to strengthen the security of all transaction and account information.

4.	Payment
Athlete Finders is a free tool to use however, to get access to additional features and benefits, there is a cost involved with a subscription plan.

When selecting a specific subscription level, you’ll honour your payment obligations (all payments are taken instantly before user gets any access to the specific subscription level (for players; Sub 1, Sub 2 and Sub 3 & for scouts; Sub 1 to get additional features and services) which was selected by the user) and you are okay with us to repeat the transaction on a monthly basis (based on the level of subscription selected) unless there has been any change of subscription level by the user. To cancel the subscription, you can easily select back to the freemium agreement which we will be aware that you’re no longer wanting to continue the subscription however you must do this at least 1 day before the next billing date (Billing date is when you first went on to the subscription (for example, if you went on to any subscription from 23rd September, then we will bill you each month on the 23rd).

Key information:
There may be fees and taxes that are added to our prices
We don’t guarantee refund
If you purchase any of the Subscription Services, you agree to pay the applicable fees and taxes. If failure to pay these fees, Athlete Finders have the right to terminate your subscription
Due to exchange rates, purchase may be subject to foreign exchange fees or differences in prices based on location
Unless you cancel your subscription, you must pay us for applicable fees and taxes, in which case you agree to still pay these fees until the subscription ends within the specific period
Athlete Finders will send an email of your receipt for the purchase of the subscription and it should also occur on your PayPal account history for all transaction
5.	Notices and Service Messages
You agree that Athlete Finders will provide notices on any changes and updates by using our websites, (in the future via mobile apps), and email to provide you with important notices. This Agreement applies to mobile applications as well. Also, you agree certain additional information can be shared with us. Athlete Finders will provide notices in the following method; a) email and b) notification function (announcement) within Athlete Finders platform. It is your responsibility to update your contact information in order to receive Athlete Finders notices otherwise you may miss out on these notices. If you disagree to the changes or our updates on User Agreement, the Privacy Policy or any other changes, then we advise that you close your account and not have access to our services. If you continue to use the agreement, it will mean that you’ve agreed with the changes of any agreement or updates,

6.	Messages and Sharing
Within Athlete Finders, there are many content and functions that allows users to share information, send and receive messages, post information, images, videos from personal device, YouTube or Vimeo, events, future activities and many more. With all the information and activities that are shared within the platform, all will be shared to other users who are members and visitors of the platform. The visitors won’t be able to view profile page unless they sign up or log in. When you share information, others can see, copy and use that information however, on the Athlete Finders privacy section within the setting, it allows you to take control, provide restriction on who can see etc. If there isn’t a feature that restrict you to expose or prevent copyright etc., Athlete Finders recommend to not upload information or post any video, articles, and images etc., as we will not be liable for any dispute for what you share on the platform. All users are accountable for what they upload as we have no control until a report has been made where we will follow a process to handle the situation in the best manner.

Note that any information or post that is against the User Agreement or anything that breeches against the agreement, all information and post will be removed and can lead to termination.

7.	Your License to Athlete Finders
Within the platform, there are couple of setting option to help you decide who gets to see your information and content however, you commit to the agreement that every information you upload, on to the platform, including personal profile, post and videos (Including YouTube and Vimeo URL links) etc., that it’s 100% integrity that it belongs to you or you have the right to share and it doesn’t breech against anyone else’s properties.

Everything you upload that’s part of the content, feedback, and personal information which you upload on to Athlete Finders platform or provide to the organisation via email, telephone or any sort of communication, you also grant the company a non-exclusive license to it.

You own the information, post, video, all contents that you submit or post on the platform, you give permission to Athlete Finders in the following non-exclusive license:

A)	A worldwide, transferable and sub-licensable right to use, copy, modify, distribute, publish, and process, information and content that you provide through our Services, without any further consent, notice and/or compensation to you or others.

These rights are limited in the following ways:

You can eliminate your license by deleting your account or delete the specific content from the platform, except:

(a)	You may have already shared to the extent where other users on the platform may have copied or stored it

(b)	Realistically it may take reasonable time for us to remove from the back-up system or any other relevant system


We will not include your content in advertisements for the products and services of others (including sponsored content) to others without your separate consent. However, we have the right, without compensation to you or others, to serve ads near your content and information, and your comments on sponsored content may be visible as noted in the Privacy Policy.

All other members or visitors may have access to the content and information you have shared, and this occur consistently based on your settings and degree of connection on the platform however we have no control to stop other publishing it outside the platform therefore it is important you’re accountable to first check the setting option to restrict certain criteria or don’t upload it at all.

As you own the information and content, while we have the non-exclusive rights to it, you still have the control to make it available to other members. We may be able to edit the content in terms of size, format, quality, file type, translate and removing metadata however we will not be able to modify the meaning of your expression.

According to the Privacy Policy within Athlete Finders services, you agree that we may access, store and use any information that you provide in accordance with the terms of the Privacy Policy and your privacy settings.

It is Athlete Finders’s policy not to accept or consider content, information, ideas, suggestions or any other materials other than those we have specifically requested and to which certain specific terms conditions and requirements may apply. This is to avoid any confusion as you may feel or perceives your ideas are similar to those we have developed or are developing independently. Athlete Finders Ltd does not accept unsolicited materials or ideas, and takes no responsibility for any materials or ideas transmitted. If, despite our policy, you choose to send us content, information, ideas, suggestions, or other materials, you further agree that Athlete Finders Ltd is free to use any such content, information, ideas, suggestions or other materials, for any purposes whatsoever, including, without limitation, developing and marketing products and services, without any liability or payment of any kind to you.

Depending on the country, Athlete Finders may be required to remove certain information or content to cooperate with the law therefore you agree that your profile information will be truthful, provide content or any information that doesn’t violate the law nor anyone’s right of intellectual property right or breaching an agreement.

8.	Service Availability
Due to any circumstances, Athlete Finders can’t promise to keep or store information to show the contents that have been posted in the past. This is because the company may go through a change, innovation, update, upgrade, change of plan, change of system, remove or improve; therefore we may change or discontinue any content of our platforms.

This will also modify prices prospectively in our discretion, nevertheless Athlete Finders will notify all members and new members to the extent where it is allowed under the law, and then the changes will be implemented upon the notice provided to you.

Between you and Athlete Finders, you agree that we have no obligation to maintain, store or provide any member a copy of any information or content that you or others provide, except to the extent required by applicable law.

9.	Other members, their Content, Third Parties, Sites and Apps
You will be accountable to be at risk if you use others’ information or contents posted on the platform.

You represent and warrant that: a) you own the content posted by you on or through the service or otherwise have the right to grant the rights and licenses set forth in these terms of use; b) the posting and use of your content on or through the service does not violate, misappropriate or infringe on the rights of any third party, including, without limitation, privacy rights, publicity rights, copyrights, trademark and/or other intellectual property rights; c) you agree to pay for all royalties, fees, and any other monies owed by reason of content you post on or through the service; and d) you have legal right and capacity to enter into these terms of use in your jurisdiction.

You agree that Athlete Finders is not responsible for, and does not endorse, content posted within the service.

Other members may form their own organisation or be linked to an organisation which they (Third parties) may offer their own products and services through Athlete Finders. We request all users to state which company they represent to help us improve integrity however we aren’t responsible (or liable) for those third-party activities or any users who promote their services to other users. It is down to all member/non-members to do their own due diligence to research about each user’s profile or the company before committing to any business or interest of activities (for example, if a Scout has scouted a player, and the scout gives you a call to arrange a trial or invitation to the club for training etc., it is the players or any other users to do their due diligence before committing to any legal activities).

When using Athlete Finders, you agree that we are not responsible for any third parties activities as well as for any member/non-member who is found via the platform(s), even if it’s linked to a content or information, we are not liable. You may find information or content that could be misleading, fraud, illegal, inaccurate, incomplete, offensive, harmful, or delayed but it’s the user’s responsibility to be cautious and check through external website or any other source to check before committing to any activities as Athlete Finders does not review content provided by our members.

It Is your responsibility if you decide you want to access or use third parties link to visit their website or mobile app from our platform. An app or a site can access your information within Athlete Finders platform, such as profile information and your followers and who you’re following. This will only happen if you give permission to a third party app or site to authenticate you or connect with your Athlete Finders account. We are not responsible for any third party sites and app therefore you must read their terms of agreement and privacy policies, which you may be giving others permission to use your information.

10.	Limits
Scout me has the right to limit how you use, interact, promote or any activities within the services as we’re providing you notice about our intellectual property rights. Athlete Finders reserves all of its intellectual property rights in the services, such as the logo, the icon and all copyright of designs within the service (platform). The Athlete Finders name and logo are trademark of Athlete Finders Ltd, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Athlete Finders Ltd.

Depending on how you use our services, we have the right to restrict, suspend or terminate your account due to miss cooperation, abuse the services, and breech against the law and our agreement.

11.	Disclaimer and Limit of Liability
This is our disclaimer of legal liability for the quality, safety, or reliability of our services. TO THE EXTENT ALLOWED UNDER LAW, ATHLETE FINDERS (AND THOSE THAT ATHLETE FINDERS WORKS WITH TO PROVIDE THE SERVICES) (A) DISCLAIM ALL IMPLIED WARRANTIES AND REPRESENTATIONS (E.G. WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY OF DATA, AND NON-INFRINGEMENT); (B) DO NOT GUARANTEE THAT THE SERVICES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS, AND (C) PROVIDE THE SERVICE (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAIL’BLE” BASIS. SOME LAWS DO NOT ALLOW CERTAIN DISCLAIMERS, SO SOME OR ALL OF THESE DISCLAIMERS MAY NOT APPLY TO YOU.

12.	Exclusion of Liability
These are the limits of legal liability we may have to you. TO THE EXTENT PERMITTED UNDER LAW (AND UNLESS ATHLETE FINDERS HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT SUPERSEDES THIS AGREEMENT), ATHLETE FINDERS (AND THOSE THAT ATHLETE FINDERS WORKS WITH TO PROVIDE THE SERVICES) SHALL NOT BE LIABLE TO YOU OR OTHERS FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF DATA, OPPORTUNITIES, REPUTATION, PROFITS OR REVENUES, RELATED TO THE SERVICES (E.G. OFFENSIVE OR DEFAMATORY STATEMENTS, DOWN TIME OR LOSS, USE OR CHANGES TO YOUR INFORMATION OR CONTENT). IN NO EVENT SHALL THE LIABILITY OF ATHLETE FINDERS (AND THOSE THAT ATHLETE FINDERS WORKS WITH TO PROVIDE THE SERVICES) EXCEED, IN THE AGGREGATE FOR ALL CLAIMS, AN AMOUNT THAT IS THE LESSER OF (A) FIVE TIMES THE MOST RECENT MONTHLY OR YEARLY FEE THAT YOU PAID FOR A PREMIUM SERVICE, IF ANY, OR (B) BETWEEN £50 TO £100. THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE BARGAIN BETWEEN YOU AND ATHLETE FINDERS AND SHALL APPLY TO ALL CLAIMS OF LIABILITY (E.G. WARRANTY, TORT, NEGLIGENCE, CONTRACT, LAW) AND EVEN IF ATHLETE FINDERS HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE. SOME LAWS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY, SO THESE LIMITS MAY NOT APPLY TO YOU.

13.	Termination
You can terminate any time and likewise, we can terminate any account that belongs to a member based on our agreement if a member breeches against the terms of services, policy and any other agreements. We will notify you if we do terminate your account.

Whether you or Athlete Finders terminates your account, you will not have access to use our services as you lose the rights however, if your account has data with performance analysis, we will not be able to terminate your profile as it will effect the team data of team analysis.

The following shall survive termination:

Our rights to use and disclose your feedback;

Sections 11, 12, 14 and 15 of this Agreement;

Any amounts owed by either party prior to termination remain owed after termination.

After termination, we agree that all information of profile and all activities and data will be deleted

14.	Dispute
If we end up in a legal dispute, which is very unlikely, it will take place in England and Wales, applying the English law. You agree that the laws of the England and Wales, (U.K), and we both agree that all of these claims can only be litigated in the UK.

15.	General Terms
In some cases, the court with authority may find some part of an agreement is not enforceable therefore we’re providing a term that you and us agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce the rest of this Agreement. To the extent allowed by law, the English version of this Agreement is binding and other translations are for convenience only. This Agreement (including additional terms that may be provided by us when you engage with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.

If we don’t act to enforce a breach of this Agreement that does not mean that Athlete Finders has waived its right to enforce this Agreement. You may not assign or transfer this Agreement (or your membership or use of Services) to anyone without our consent. However, you agree that Athlete Finders may assign this Agreement to its affiliates or a party that buys it without your consent. There are no third party beneficiaries to this Agreement. We reserve the right to change the terms of this Agreement and will provide you notice if we do and we agree that changes cannot be retroactive. If you don’t agree to these changes, you must stop using the services. You agree that the only way to provide us legal notice is at the addresses provided in Section 19 of this User Agreement.

16.	You agree that you will:
Commit to our terms and User Agreement with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements.
With the right intention to use the service with integrity, provide accurate information and continue to keep updating all personal information, contacts, email, your real name, name of the company which you’re representing (past and present).
Use the Services in a professional manner to network with scouts, agents, clubs, players or any sport organisations, you’re willing to use the service with the right intention to promote your business or yourself to either recruit, promote, advertise, sell or any other business activities that is legal.
17.	You agree that you will not:
Abuse any member, use our logo to misrepresent our members of trickery, misuse of our services, abuse our team, and go against our User Agreement, Privacy Policy and any other agreement.
You will not act dishonestly or unprofessionally. This includes that you won’t put an image that isn’t yourself within the profile image, a name that isn’t correct or that doesn’t match your passport or driving license.
You will not be posting inappropriate, inaccurate, or objectionable contents; All field must be entered and must be accurate to your personal information, such as; submitting a telephone, email addresses, age or any personal information for which there is not a field provided by Athlete Finders; Misrepresent any information about your identity, image, personal background in terms of time-line, history, education, courses, companies that you worked for or currently working for, previous and current position, role, achievements, videos that belongs to someone else and it isn’t yourself who is in the video (for example, a user may upload someone else’s video to claim that is them).
You will not create a false profile or identity on Athlete Finders, misrepresent your affiliations with a person or entity, past or present, your identity, including but not limited to the use of a pseudonym.
You will only create a profile for yourself and you will not create another profile or for anyone else (even if they’re a real person, as they must do it themselves)
You will not attempt to use another user’s account, harass or harm any member.
You will not send spam, fraud information or any other form of communications to other members or on the platform.
You will not copy other member’s information or their profiles through any means (including crawlers, browser plugins and add-ons, and any other technology or manual work).
You will not discriminate, act in an unlawful, be abusive, obscene, libellous, violate intellectual property rights of others, including trademark (plus trademark secrets), patents, proprietary or copyright.
You will not disclose information that doesn’t belong to you or anything that you do not have the right to disclose (for example, confidential information of others, it could even include your employer).
You will not violate Athlete Finders intellectual property and all other rights, including, without limitation, using our logo, the word “Athlete Finders” or the football icon from the logo which nothing related to Athlete Finders appears in any business name, email, or URL
You will not use our invitation to send random email/messages to people who you don’t know.
You will not discriminate anyone, abuse or hate by providing false information, such as rating a member’s profile of his/her attributes (skills) and behaviour from a scale of 1 to 10.
You will not upload events that’s not true, nor will you put inaccurate address, promotion, or anything related to the feature of ‘Event’ on Athlete Finders to mislead any other members.
You will have the right intention when committing to an event if you’re going to attend or not but you will not mislead anyone on purpose to deceive the owner of the event or to any other members.
You will not upload any inappropriate images, videos, links, articles, comments or anything that can be advertised to other members.
You can’t share or disclose information of other members without their consent.
You will not advertise any services, business activities or anything to promote that can mislead members to illegal activities, fraud, criminal, violence or any illegal activities.
You will not post any unsolicited or unauthorised advertising, such as “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation which is unauthorised by Athlete Finders.
You will not post anything that affect our services, anything that contains software viruses, worms, or any other harmful code to affect members and the platform.
You will not manipulate identifiers in order to deceive the origin of any post transmitted through the Services.
You will not create a profile that promotes negative images, negative names of organisation, such as escort services, prostitution or anything that is illegal.
You will not create an account to promote a business which leads to fraud or other similar practice.
You will not use any content, information or data of others available on the services, nor will you copy it unless it has been authorised to the person who has the rights.
You will not copy or use any content or data on our platform that is linked with a competitive service (as determined by Athlete Finders).
You will not create derivative works on Athlete Finders, nor copy or modify the services or any related technology unless it has been authorised by Athlete Finders.
You will not try to copy, disassemble, decompile, reverse engineer or attempt to derive our source of the service we provide or any technology (this includes even if it’s a small-part).
You will not state, declare, promote or imply that you are affiliated with or endorsed by Athlete Finders without our express consent (for example, you may try to represent as an agent behalf of Athlete Finders or associate in any form).
You will not monetise on our platform with any account as a member, group, organisation or any other services without the consent of Athlete Finders. This includes that you can’t trade, sell/re-sell, lease, rent or loan access to our services or anything related to data and information.
You can’t remove any trademark, copyright, or other proprietary rights notices contained within our services.
You can’t cover, obscure or remove any advertisement included on Athlete Finders.
You can’t use, collect, transfer or copy any information obtained from Athlete Finders without our consent.
You will not use any devices, script robots, manual software, automated software or other means or processes to access, “scrape,” “crawl” or “spider” the services or any related data or information from Athlete Finders.
You will not use automated methods to access the services or use bots to add, download contacts, send or redirect messages.
For competition reason, you can’t monitor our services’ availability, functionality or performance for any competitive purpose. This includes that you can’t stimulate the appearance, mirroring, engage in framing for any of our functions of the services.
You can’t access Athlete Finders except through the interfaces expressly provided by us, on the desktop www.scoutmeonline.com and in the future application for mobile and tabloid devices.
You will not override any security feature of our services, this includes that you can’t interfere with operation of, or place an unreasonable load on the platform (such as viruses, spam, gaming algorithms and denial of service attack).
18.	Complaints Regarding Content
We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties. We provide a policy and process for complaints concerning content posted by our Members.

19.	Contact for dispute or any legal matter
Please email to legal@scoutmeonline.com.

20.	Stats Analysis Service (Paid service & free trial / demo):
No obligation to continue after the first analysis but you agree to give us feedback on how we can make it better for you and we will use your images and stats of players and coaches to show case positive stats for our brand awareness.
No upfront payment until you want to commit after the free trial but if it’s a paid service, we must receive payment upfront for specific package and deliver performance analysis data within agreed time for turnaround.
If you are the customer who’s requesting to get your games analysed either for yourself or for your club to get individual and team performance analysis (this includes customers on free trial and paid customers), by sending us the video of the match, you are agreeing that all players and coaches are aware that Athlete Finders will create profiles of each member as you’re authorising it behalf of them. With each profile, we need to tag each player in every action to give full stats analysis and video replay of players and team performance.
All players and coaches profiles will be visible with stats and video analysis so that coaches and club representative can view performance and make improvement. Each member can request a login credential to make their profile private where it will only be visible to themselves and their followers.
Team performance analysis will be visible unless the club profile is switched to private where only members within the group will only have access to view all features within the platform and data analysis.
For any concern or changes required for private account and restriction, you can communicate with your account manager from the beginning when you use our service.
Minimum of 1 game will be analysed for free (if it’s a free trial) however, if we feel there is a potential benefit for both parties, we may agree to complete more than 1 game. We are entitle to stop at any time and you’re also not oblige to pay or continue unless new agreement or payment plan has been set up’.
All new customer must expose our service in exchange for the free analysis we have provided to players alone, or the club for the team performance. You can expose our brand on all your social media channel after every stats analysis we’ve completed (per game). This could be player recognition of improvement or anything related to what we contributed after each match that was analysed and within the post, we asked that you put a statement ‘analysed by Athlete Finders (use the Logo of our company instead of the text) ‘Athlete Finders.’
You agree that we can expose players or coaches for recognition or successful stories and updates (i.e. improvement, promotion, new contract, recognition within club or on our platform Athlete Finders) with best stats by exposing both parties for brand awareness.
All paid service, a payment must be cleared before committing to 1 game analysis or monthly plan to complete 4 analysis per month (players service or team service). As a company, Athlete Finders may have bespoke plan with client to deliver multiple of match analysis per month.
All paid service will vary with agreement to deliver the match analysis within specific time and days based on price where company can provide the resources to deliver. The start time can only proceed once Athlete Finders has the correct information of line up, quality video that is able to identify players and their squad number. When we refer agreed plan, a customer and business account manager on a weekly routine will have scheduled to receive video and start project consistently on a specific day of the week and time. If we don't receive the video as agreed day and time, we can’t guarantee a quick turnaround to meet the deadline.
All free trial will not be expected to have a quick turnaround after receiving the video as our commitment is to paid customer before we can commit to any free service.
All data of performance analysis of players, coaches, club and other information within the platform will be the sole property of Athlete Finders. All intellectual property rights in the app, the Desktop and the Services throughout the world belong to Athlete Finders and the rights on it’s platforms and the Services are licensed (not sold) to you. You have no intellectual property rights in, or to, the App, the Desktop or the Services other than the right to use them in accordance with these terms.
All users who are subscribing or non-subscribing to use the software to analyse matches (including users who are part of Athlete Finders team like analyst and business account manager), you agree that before taking any project to analyse matches for a player or for the team, that the client is aware of Athlete Finders terms which players profile will be created on the platform. You would have explained how we use it and why for tagging the player. You understand that once the client has shared their line up info, and video for you to analyse, at this point the client is aware and they have informed all players or anyone that represent the player is informed and fully aware of what we do and how we analyse otherwise they shouldn’t have shared the line up info and the video if they didn’t agree. If the client decides to dispute this, you are agreeing that you have evidence in your written communication via messages on any mobile application or email. It is your responsibility that you inform the client and have a record of your communication as Athlete Finders will not be liable for your action as it is your action. Athlete Finders can’t manage this except provide training, and updates to educate all users who analyse matches that they follow these terms. You are liable for any dispute and damages therefore we advised that you always inform the clients about these terms.</p>
                {/* <p className='text-gray-400 font-semibold'>Last revised on 3rd March, 2019</p>

                <p className='text-lg text-gray-400'>We are a performance analysis company for athletes and all football enthusiast within sport organisations to connect, share, engage, seek opportunities, streamline all current processes and recruit for all amateurs and professionals around the world.</p>

                <p className='mt-4'>Our short-term objective is to help players, coaches and clubs to improve their performance with stats and video replay within our software and the long-term vision is to expose players and coaches directly to scouts, agents or anyone within our network to help them get recruited with facts, and no based on bias or favouritism. We want to increase the chances of players and coaches to be judged fairly and help scouts and agents to make better decision with our stats and video replay</p>
                <br />
                <p>We are committed to protecting our member’s personal information when using our services by the industry standard safeguards. All members have the capability to make their profile private and hide their performance analysis and other key profile information.</p>
                <br />
                <p>If there is any changes to our privacy policy, we will always provide updates as according to the terms of service section 5 (Notices and Services messages) however, we may share user’s information with their consent or as required by the law however, if the profile is visible, we will share performance analysis of the user to help raise awareness of their talent to get scouted.</p>
                <br/>
                <p>In order to maintain trust and confidence from our members, we are disciplined and committed to follow the guideline in the following to protect member’s privacy.</p>
                <p className='mb-2'>If we’re to provide information to third parties:</p>

                <p>a. We will get users consent</p>
                <br/>
                <p>b. If necessary to follow user’s instruction</p>
                <br/>
                <p>c. With reasonably necessary to provide our features and functionality to usersWe will get users consent</p>
                <br/>
                <p>d. If we are required by law with reasonable, subpoena or other legal process</p>
                <br/>
                <p>e. If it’s necessary to enforce our terms of service or protect all properties, rights and safety of all members, visitors and the public.We will get users consent</p>
                <br/> */}
            </div>
            <Footer user={user} setShowModal={setShowModal}/>

        </div>
    )
}

export default TNC