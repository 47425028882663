import React from 'react'
import { auth } from '../firebase.config'

const SingleMessage = ({ message }) => {
    return (
        <div>
            <div className={`chat ${message.senderId === auth.currentUser?.uid ? "chat-end" : "chat-start"}`}>
                <div className="chat-image avatar">
                </div>
                <div className="chat-header text-[#555]">
                    {message.senderId === auth.currentUser?.uid ? "You" : null}
                </div>
                <div className="chat-bubble bg-[#333]">
                    {message?.media && message?.mediaType && message?.mediaType === 'image' ? (
                        <>
                        <img className='object-contain rounded-lg' src={message?.url} />
                            </>
                    ) : message?.media && message?.mediaType && message?.mediaType === 'video' ?  
                    (
                        <video
                            className=''
                            src={message?.url}
                            autoPlay
                            // controls
                            />
                    )
                     :
                    (
                    <p>
                    {message.text}

                    </p>
                    )
                }
                </div>
            </div>
        </div>
    )
}

export default SingleMessage


