import React from 'react'
import BB from '../assets/l1.png'
import 'animate.css';

const About = () => {
    return (
        <div name="about" className="bg-gray-100 py-16">
            <div className="container  mx-auto flex flex-col-reverse lg:flex-row items-center m-7">
                <div className="lg:w-1/2 pr-8 m-3 animate__animated animate__slideInLeft">
                    <h2 className="text-3xl font-semibold mb-4">About Us</h2>
                    <p className="text-gray-700 mb-20">
                        To solve our mission, we built our own software that looks world class level to analyse unknown football players and teams that is much faster and cheaper for any analyst to use.
                    </p>

                    <a href='/About' className='bg-blue-700 rounded-lg text-white p-3'>
                        Learn More
                    </a>
                </div>
                <div className="lg:w-1/2 m-3 animate__animated animate__slideInRight">
                    <img
                        src={BB}
                        alt="About Us"
                        className="w-full rounded-md"
                    />
                </div>
            </div>
        </div>
    )
}

export default About