import React, { useState } from 'react';
import { auth, db } from '../firebase.config';
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        gender: 'male',
        dateOfBirth: '',
    });

    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);

    const navigate =useNavigate()

    const handleInputChange = (e) => {
        setFormError(null)
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.fullName || !formData.dateOfBirth) {
            setFormError('Please fill out all required fields.');
            return; // Exit the function early
        }



        //add data to the form
        try {
            setLoading(true);
            await setDoc(doc(db, "Users", auth.currentUser?.uid), formData)
                .then(() => {
                    setLoading(false)
                    alert("Profile Edited!")
                    navigate('/')
                }).catch((error) => {
                    setLoading(false)
                    alert(error.message)
                })

        } catch (error) {
            setLoading(false)
            alert(error.message)
        }
    };

    return (
        <div className="w-full max-w-md mx-auto py-32">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullName">
                        Full Name
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="fullName"
                        type="text"
                        name="fullName"
                        placeholder="John Doe"
                        value={formData.fullName}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
                        Gender
                    </label>
                    <div className="relative">
                        <select
                            className="block appearance-none w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="gender"
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                        >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M12.293 7.293a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 9.414l-7.293 7.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0V7.293a1 1 0 010 1.414z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateOfBirth">
                        Date of Birth
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="dateOfBirth"
                        type="date"
                        name="dateOfBirth"
                        value={formData.dateOfBirth}
                        onChange={handleInputChange}
                    />
                </div>

                {formError && <div className="error-message">{formError}</div>}
                <div className="flex items-center justify-between">
                    <button
                        disabled={loading}
                        className="bg-[#2b14dd]  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        {loading ? "Loading..." : " Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditProfile;
