import React, { useEffect, useState } from 'react'
import Hero from '../components/Hero'
import About from '../components/About'
import Support from '../components/Support'
import AllInOne from '../components/AllInOne'
import FeatureGrid from '../components/Texts'
import Footer from '../components/Footer'
import ChatIcon from '../components/ChatIcon'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase.config'

const Home = ({ setShowModal }) => {
    const [user, setUser] = useState(null); // Store the authenticated user

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user); // Store the authenticated user
            } else {
                setUser(null); // No user is authenticated
            }
        });

        return () => unsubscribe();
    }, [user]);

    // if (user === null) {
    //     // Waiting for authentication to complete, you can show a loading spinner
    //     return <div>Loading...</div>;
    // }
    return (
        <div>
            <Hero />
            <About />
            <FeatureGrid />
            <Support user={user} setShowModal={setShowModal} />
            <Footer user={user} setShowModal={setShowModal}/>
            <ChatIcon user={user} setShowModal={setShowModal} />
            {/* <AllInOne /> */}
        </div>
    )
}

export default Home