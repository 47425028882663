import { ChatAlt2Icon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import { auth, db } from '../firebase.config'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

const ChatIcon = ({setShowModal,user}) => {
    const nav=useNavigate();
    const [loading,setLoading]=useState(false)
    return (
        <div 
        onClick={async () => {
            if (auth.currentUser) {
                setLoading(true)
                const docRef = doc(db, "rooms", auth.currentUser?.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    nav(`chat/${docSnap.id}`, { state: { id: docSnap.id } })
                } else {
                    await setDoc(doc(db, "rooms", auth.currentUser?.uid), {
                        time: new Date(),
                        scouteeId: auth.currentUser?.uid,
                        cemail: auth.currentUser?.email,
                        admin: '7GkoMZyFL5R2vrGxkDExy6JOMZP2'
                    }).then(() => {
                        setLoading(false)
                        nav(`chat/${auth.currentUser?.uid }`, { state: { id: auth.currentUser?.uid,user:user, admin: '7GkoMZyFL5R2vrGxkDExy6JOMZP2' } })
                    }).catch(() => {
                        setLoading(false)
                        alert("An error occured!")
                    })
                }
            } else {
                setShowModal(true)
            }
        }}
        className='w-40 h-12 text-sm bg-blue-50 shadow-md font-semibold rounded-full fixed bottom-5 mb-5 right-0 m-7 flex items-center justify-center cursor-pointer'>
       {loading?"Loading...":"Apply Now"}     <ChatAlt2Icon color='#2b14dd' className='w-7 h-7 ml-1'/>
        </div>
    )
}

export default ChatIcon