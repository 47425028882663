import React, { useEffect, useState } from 'react';
import { Link, animateScroll as scroll, } from 'react-scroll'
import LOGO from '../assets/logo.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { auth, db } from '../firebase.config'
import { collection, doc, getDoc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { ChatAlt2Icon } from '@heroicons/react/solid';
import { BsArrowRightCircleFill, BsCircleFill } from 'react-icons/bs';
import {TiArrowBack, TiArrowUpThick, TiGroup} from 'react-icons/ti'

const Navbar = ({ showModal, setShowModal, user }) => {
  const [data, setData] = useState([]);
  const [Show, setShow] = useState(false);

  const [nav, setNav] = useState(false)
  const handleClick = async () =>  {
    setNav(!nav)
    setShow(false)
  }

  const handleClose = () => setNav(!nav)

  const Chat = async () => {
    if (auth.currentUser) {
      setLoading(true)
      const docRef = doc(db, "rooms", auth.currentUser?.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
          nav1(`chat/${docSnap.id}`, { state: { id: docSnap.id } })
      } else {
          await setDoc(doc(db, "rooms", auth.currentUser?.uid), {
            time: new Date(),
            scouteeId: auth.currentUser?.uid,
              cemail: auth.currentUser?.email,
              admin: '7GkoMZyFL5R2vrGxkDExy6JOMZP2'
          }).then(() => {
              setLoading(false)
              nav1(`chat/${auth.currentUser?.uid }`, { state: { id: auth.currentUser?.uid,user:user, admin: '7GkoMZyFL5R2vrGxkDExy6JOMZP2' } })
          }).catch(() => {
              setLoading(false)
              alert("An error occured!")
          })
      }
  } else {
      setShowModal(true)
  }
  };

  const handleSignOut = () => {
      auth.signOut()
      .then(() => {
        // Remove user data from local storage
        // localStorage.removeItem("userData");

        // setUser(null);
      })
      .catch((error) => {
        console.error(error);
      });
      setNav(!nav)
  };

  
  useEffect(() => {
    const collectionVal = collection(db, "rooms")
    const q=query(collectionVal,orderBy('time','desc'))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newMessages = [];
      querySnapshot.forEach((doc) => {
        newMessages.push({ ...doc.data(), id: doc.id });
      });
      setData(newMessages);
    });

    return () => unsubscribe();
}, []);

  const [loading,setLoading]=useState(false)
  const nav1 =useNavigate();


  return (
    <>
     {
        Show ?
        <div 
        className='bg-gradient-to-r from-blue-600 to-[#2b14dd] h-[1000%] w-[100%] lg:px-60 absolute z-50'
        >
 <div 
        onClick={() => setShow(false)}
        className='bg-white font-semibold flex items-center justify-center text-[#333] fixed p-5 rounded-2xl cursor-pointer hover:bg-gray-200 top-5 lg:right-10 right-5'>
          <TiArrowBack />
          <p>&nbsp; Go Back
          </p>
        </div>
<h1 className="text-white font-bold lg:text-6xl text-4xl text-center m-10">
        Messages
      </h1>

          {data.map((data, index) => (
        
        <div
        onClick={async () => { 

          nav1(`chat/${data.id}`, { state: { id: data.id } })
          setShow(false)
          handleClick()
          // setUserData({...userData, purpose: item.department_name})
          // setSearchQuery(data.department_name)
          // setUserData({...userData, id: data.id, category: data.category, purpose: data.department_name})
          // handleNav()
          // handleAnime()
        }}
       
        className={'bg-[#eee] cursor-pointer lg:rounded-md text-start  font-bold mb-3 w-[100%] p-2'}
        >
                  <p
                  key={index} 
                  className='text-[#333] flex ml-5 items-center'
                  // onClick={handleNav} 
                  >
                   
                  <BsCircleFill color='red'  size={15}/> <p className='p-5'>
                    {data.cemail}
                    <br />
                    <i className='text-gray-400'>
                      Inbox Just Now...
                      </i>
                    </p>
                     <BsArrowRightCircleFill size={15} />
                  
                  </p>
        </div>
        
        
        ))}
        </div>
        :
        null
      }
    <div className='w-[100%] h-[100px] z-10 bg-gradient-to-r from-blue-600 to-[#2b14dd] fixed drop-shadow-lg py-10'>
      
      
      <div className='px-2 flex justify-between items-center w-full h-full '>
        <a href='/' className='flex cursor-pointer items-center lg:ml-20'>
          <img src={LOGO} className='lg:w-40 lg:h-40 h-36 w-36 object-contain mb-5 lg:ml-10' />
        </a>
        <div className='flex lg:pr-4'>
          {auth?.currentUser ? (
            <div className='flex items-center'>
              <p className='mr-3 font-semibold text-white'>
                {/* {auth.currentUser.email} */}
               You're Logged In</p>
              <img
                className='w-6 h-6 rounded-full cursor-pointer'
                src='https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg'
              />
            </div>
          ) : (
            <a
              onClick={() => setShowModal(true)}
              className=' text-white cursor-pointer'>Login</a>
          )}
        <div className='lg:mx-16 ml-10 lg:mr-32 mr-2  cursor-pointer' onClick={handleClick} onMouseEnter={handleClick}>
          {!nav ? <MenuIcon className='w-6 text-white' /> : <XIcon className='w-6 text-white' onClick={handleClose} />}
        </div>
        </div>
      </div>

     

      <ul className={!nav ? 'hidden' : 'absolute rounded-lg bg-gradient-to-r from-blue-600 to-[#2b14dd] w-72 lg:right-20 right-3 h-auto p-5'}>
        <div className='flex flex-col my-4'>
          {!auth?.currentUser ? (
            <button className='bg-white hover:bg-gray-300 text-blue-700 px-8 py-3 mb-4' onClick={() => setShowModal(true)}>Sign In</button>
            ) : (
             
              <div className=' items-center'>
             {auth.currentUser?.uid === '7GkoMZyFL5R2vrGxkDExy6JOMZP2' ? <button className='bg-white hover:bg-gray-300 text-sm text-blue-700 w-[100%] py-3 mb-4' onClick={() => setShow(true)}>All Messages</button> : null}
            <div className='flex items-center'>
              <p className='mr-3 font-bold text-sm text-white'>{auth.currentUser.email}</p>
              <img
                className='w-8 h-8 rounded-full cursor-pointer'
                src='https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg'
              />
            </div>
            
          <br/>
              
            </div>
            
          )}
          <div className='text-white text-sm'>
          <div
                   onClick={Chat}

          className='hover:border-2 p-2 cursor-pointer'
          >
            <p>
            <span className='text-cyan-200 font-semibold'>
              Football Players:
              </span> Want to get scouted by us so we can find you a new club?
            </p>
          </div>
          <div
                   onClick={Chat}

          className='hover:border-2 p-2 cursor-pointer'
          >
            <p>
            <span className='text-cyan-200 font-semibold'>
              Scouts & Agents:
              </span> Want to get scouted by us so we can find you a new club?
            </p>
          </div>
          <div
                   onClick={Chat}

          className='hover:border-2 p-2 cursor-pointer'
          >
            <p>
            <span className='text-cyan-200 font-semibold'>
              Analyst:
              </span> Want to get scouted by us so we can find you a new club?
            </p>
          </div>
          <div
                   onClick={Chat}

          className='hover:border-2 p-2 cursor-pointer'
          >
            <p>
            <span className='text-cyan-200 font-semibold'>
              Coahes:
              </span> Want to get scouted by us so we can find you a new club?
            </p>
          </div>
          <div
         onClick={Chat}
          className='hover:border-2 p-2 cursor-pointer'
          >
            <p>
            {loading?"Loading...":"Contact Us"}
                       </p>
          </div>
          {auth?.currentUser ?
          <div
          onClick={handleSignOut}
          className='hover:border-2 p-2 cursor-pointer'
          >
            <p>
           Log Out
                       </p>
          </div>
          : null }
          </div>
        </div>
      </ul>
    </div>
    </>
  );
};

export default Navbar;
