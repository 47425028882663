import React from 'react'
import 'animate.css';
import bgImg from '../assets/hero.png'
import Video1 from "../assets/v1.mp4";
import Video2 from "../assets/v2.mp4";



const Hero = () => {
    return (
        <div className="relative bg-cover bg-center w-[100%] h-screen">
            {/* Background Image */}
            {/* <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url(${bgImg})`, // Set the path to your background image
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div> */}

<div
  // style={{width: '100%'}}
  className="hidden lg:flex w-[100%] h-[100%] animate__animated animate__bounce justify-center items-center object-cover absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    // position: absolute;
    width: 100%;
    top: 10%;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${Video1} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/> 
   
   {/* Mobile */}
<div
  // style={{width: '100%'}}
  className="lg:hidden w-[100%]  h-[100%] animate__animated animate__bounce flex justify-center items-center object-cover -top-2 absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    // position: absolute;
    width: 100%;
    // top: -20px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${Video2} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/>   

            {/* Text Content */}
            <div className="absolute bottom-20  items-center justify-end">
                <div className="p-6 items-center justify-center align-middle">
                    <h1 className="text-4xl font-bold text-white text-center animate__animated animate__slideInDown"></h1>
                    {/* <h1 className="text-4xl font-bold text-white text-center animate__animated animate__slideInDown">We believe every talent should be judged with data & video analysis.</h1> */}
                    <div className='animate__animated animate__bounce border-8 mt-11 w-80 lg:w-96 p-2 items-center justify-center ml-6 border-red-600 self-center rotate-12'>
                        <p className='text-center text-red-600 font-bold text-lg'>Say No to Bias!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero