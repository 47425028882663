import React, { useEffect, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase.config'
import Footer from './Footer';


const Privacy = ({ setShowModal }) => {
    const [user, setUser] = useState(null); // Store the authenticated user

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user); // Store the authenticated user
            } else {
                setUser(null); // No user is authenticated
            }
        });

        return () => unsubscribe();
    }, [user]);

    return (
        <div className='py-32'>
            <div className='m-4'>
                <h1 className='font-bold text-2xl p-10'>Privacy Policy</h1>
                <p className='text-gray-600 p-10 font-semibold'>Introduction to our Privacy Policy
Last revised on 3rd August, 2021

We are a performance analysis company for athletes and all football enthusiast within sport organisations to connect, share, engage, seek opportunities, streamline all current processes and recruit for all amateurs and professionals around the world.
Our short-term objective is to help players, coaches and clubs to improve their performance with stats and video replay within our software and the long-term vision is to expose players and coaches directly to scouts, agents or anyone within our network to help them get recruited with facts, and no based on bias or favouritism. We want to increase the chances of players and coaches to be judged fairly and help scouts and agents to make better decision with our stats and video replay

We are committed to protecting our member’s personal information when using our services by the industry standard safeguards. All members have the capability to make their profile private and hide their performance analysis and other key profile information.

If there is any changes to our privacy policy, we will always provide updates as according to the terms of service section 5 (Notices and Services messages) however, we may share user’s information with their consent or as required by the law however, if the profile is visible, we will share performance analysis of the user to help raise awareness of their talent to get scouted.

In order to maintain trust and confidence from our members, we are disciplined and committed to follow the guideline in the following to protect member’s privacy.

If we’re to provide information to third parties:

a)	We will get users consent

b)	If necessary to follow user’s instruction


c)	With reasonably necessary to provide our features and functionality to users

d)	If we are required by law with reasonable, subpoena or other legal process


e)	If it’s necessary to enforce our terms of service or protect all properties, rights and safety of all members, visitors and the public.

Our privacy policy applies to all users who signs up to become a member or any visitor on all our services (includes anything that is linked to Athlete Finders  services) and including any future applications and services. This includes customers who represent their club, their players where they might be coach and want to use our free trial service for a demo, then we are trusting that the customer has agreed within their organisation to proceed with our agreement. If we make any changes to our terms of services and privacy policy, users can object to the changes by closing their account. If user decides to continue using our services after we’ve communicated about the changes and updates, this will mean that they have agreed to the changes of the privacy policy and all user agreements on Athlete Finders . We may have players with performance analysis data where players may not be aware or might have been informed by their coach however, this is the responsible of the coach who requested to use our service in paid or free trial agreement.

1.	What information and how do we collect it?
The privacy policy applies to all members and visitors as we’re able to collate information from anyone that visits our platform. We will use the information for the best interest, experience and creating opportunities for all members and visitors on our platform.

All personal information provided to or collected by our Services is controlled by Athlete Finders  Ltd, in our London Office.

We collect user’s personal information in the following ways:

1.1.	Registration and Profile Information
During the registration, it’s compulsory that the user provides their correct name, email and telephone when using our services to maximise potential opportunities to succeed on the platform. We also require users to tick the box to accept the terms of services and add a personal password to secure their account as it is their responsibility to keep it confidential.

As mentioned above, if users want to get the best out of our services, all users will need to provide additional information on the profile page (for example, their city and country for location, job title, contract status/expiry date, height, weight, age, past and current company, education, achievement, skills, behaviour, attributes, their background, videos and many more). These information will strengthen their profile and to provide the users a more customised services on our platform (for example; updates, content, more relevant ads, relevant information and opportunities to their activities on the platforms). It’s better you don’t use this service if you can’t upload personal information as we need to protect all other users to help others identify each other when collaborating, recruiting and providing services to each other (for example, a Scout needs to give much information to give confidence to other members when users see invitation for trials, match, any events, jobs, new career, new club, and many more).

We may analyse your matches as an individual player service or if someone that represents the club (e.g coach or club secretary etc.) we may analyse every single player for the club in one specific match or several matches to provide individual player and team stats. When we finish analysing, the stats will appear on players profile, coaches profile and club profile. Each football player’s profile will find stats that is relevant to their performance when we have tagged for each action etc. while the team stats will reflect on club’s profile and coach’s profile. If you want to prevent others seeing your stats from your profile, whether you’re a coach or a football player. Both can visit setting and make the profile private for you only or your followers that you accepted to view your page only. For the club profile, you can make it private and only the members who are part of the club will have access to those team and individual’s stats. You are in control of what you want others to see or not however, you can always delete your profile which the stats will disappear and effect individual and team stats. If you’re not aware that you have a profile, it is the representative of the club or company that requested our service to help improve your performance as individual or for the whole group which you will need to speak with the relative person from the club or company.

All non-football player account (coaches, scouts, and agents etc.), when you join the platform, we will need to vet all new members and verify your account before we give you access to use the platform. We will ask for passport/driving license for proof of ID and if you’re selecting to say you’re a scout, coach or an agent, then you will need to provide a certificate to proof that you’re certified for that role. As stated above, we don’t have any interest with your document as they are stored an our database to know that we’ve verified you’re account and others can trust you for any future business activities. This is mandatory to protect all users and do our best to have a strong system that prevents any criminal activities.

1.2.	Sharing and Invitation
Users can invite people by sending an email on our ‘Invite function’ which will have a template message and allows users to edit and send an invitation. We also use their contact and personal information to help users find people they may know from their network and contacts so that it will enhance their experience on our services. We may also use this information to show users and other members that they share the same contacts that may or may not be a member. In addition to invitation, users contact details such as email will be advertised when an invitation is sent to a contact that they’re inviting to our service. All invitation will go via email until any changes, we will update as according to our agreement.

All users will be shared amongst all department of Athlete Finders  for security protection and for all other benefits according to the terms of service and privacy policy. We also have suppliers who have access to our services for development of our services therefore user on our platform are agreeing that they understand, and acknowledge that this is how we can only cooperate and if they continue to use our services then it means you’re agreeing to this policy. All personal information will be restricted and we will avoid exposing as much information however we will need suppliers to have access to our service to improve the experience of our users in all department such as; IT, marketing, sales, graphics and design, operation, legal and business development. We want to continue innovating and improve the experience for all our users and achieve our long term vision at the same time we understand that all our members are important and will commit 100% effort within our resources to protect all data.

1.3.	Customer Service
At some cases, user may provide feedback, compliant, informing us about abusive or anything within the platform however we may need to access their profile account (if any issues that is a threat or can harm any members and the services) to investigate the problem and support all users. In this particular instance, we won’t use information to advertise but use it for our self to learn, and improve the services for the benefit and experience of all members.

For all users that log in or just visit our site (includes all applications and services), we collect information for all activities such as; what the user’s view, clicked, spend more time on a particular content, which features they use more frequently, search, post, share, all communication, from what devices (IP address and information about the devices), and usage of information.

1.4.	Third-parties, Cookies and Web Beacon
We collect information when users use their account to sign in to other sites or services, and when they view web pages that include our plugins and cookies.

Web pages that includes our plugins and cookies, we will be able to collect information about the accounts that the users has sign in to on other services. This includes that users acknowledge and agree to it therefore each time they log in, you will notice that we will recommend a tailored content to them, based on their information that is collated.

They allow us to receive information when they use their account to log in to a third-party website or application. If they embed our social plugin such as ‘share’ on third party website or application, we will get the information and be aware that all information has been uploaded in their web browser.

The cookies and similar technologies help us to learn about user’s interest when using our platform as well as off the platform so that they can have the best experience when we provide the right content and adverts. By using the advertising technology and web beacons, users give us the consent to provide information on ways to opt out of targeted ads, including Ad choices icon shown with any ads we serve on third party sites. To opt out, users will need to close their account.

1.5.	IP Addresses, Advert, Campaign and Information About all Devices
For each time a member or visitor comes to our site from another service, or the site they’re looking to go to from our service, we automatically receive the URL of the site (including visitors). At the same time, we collate information from the devices and networks that they use to access our Services whereas advertisers receives URL of the page that the user was on when they clicked an ad or through our platform.

As a new start-up, we’re constantly innovating to improve for all our users experience which means they (users) will see many new feature which could affect the privacy policy such as a new way to collate information, communicate through devices and third parties however we will always inform as according to the terms of services.

2.	How user’s information is used?
All information on personal profile page, activities such as post, following, followers, all communication, posting comment, like, ratings, viewing other user’s profile, uploading images, videos event, attending to an event or any other activities are all exposed to other members of the platform, our team and to the public unless user controls their restriction within privacy setting. If user continue to use our service, regardless if they can’t restrict much of the features and contents, it will mean they agree to our User Agreement terms and Privacy Policy. Supplying to us any information deemed “sensitive” by applicable law is entirely voluntary on their part.

2.1.	Communications
Athlete Finders  will communicate via email and the website with all users therefore it is the user’s responsibility to check their mail, inbox or junk for any updates, security, promotion, and new features, notification of changes on policy, user agreement or anything within our services. It is important that all users update their email account in order to receive the emails from us or log on to the site frequently.

When a scout is interested in a player, they will need to click on the button ‘Scout Me’ which the player won’t be able to see this when they look at their own or other player’s profile. This is because the users who are scouts (agent and coaches) will have this feature and function for the purpose of scouting players. Once the scout clicks on it, the players (users) will get a notification via email or on the service (website / app) that they got ‘scouted’ and will automatically reveal their contacts so the scout can either email, send a message or call the player for future opportunities. Anything from that point of communication (outside of the Athlete Finders  service), we are not liable as it’s all users responsibility to check all information, research on the internet and delegate with all people before signing, making agreement, or any forms of services and business transaction. If they receive any abusive language or harmful, they must contact us to support and to help us identify the user where we can take further action to report to local police or remove them permanently off the platform.

Our objective is to help players expose themselves for more opportunities likewise for scouts, to find more opportunities of talented players; we have made our newsfeed universal. Meaning all users who join, upload videos and post, make changes, will be visible on the newsfeed even if another user isn’t following the users new post and vice versa to increase exposure. For talent recruitment.

2.2 Users can monitor who viewed their profile
All players will have a dashboard which they can see if a scout or other users have visited them. For those on the freemium service (non-subscription), they will only see the figures whereas those who are on the subscription, they can view to see who viewed the profile nevertheless if a user who is a scout and has put restriction on their privacy setting, then there isn’t much we can do if you can’t view their profile due to the user’s privacy settings.

2.2.	Sharing Information with Third Parties
Users like scouts can share the URL link to another user who could be of a football player. The purpose of this feature is to allow scouts to promote a player by communicating with their client, partner or any organisation to refer or recommend the player for his ability and profile information. This could also happen that the scouts or any other users (including players) can share URL of videos, events and profile on to another service such as email, Facebook and many more etc. We see this as a digital CV therefore we allow users to have the ability to share the link which all users must be aware that you are voluntarily agreeing to this for the best Interest of all users to succeed. However there is the option where users can restrict non-member to see less. Overall, it is to make other people’s lives easy to promote players, events and many other services.

Anything that isn’t published on user’s profile or generated through activities, we will not disclose information to any third parties unless with the consent. This also means we don’t sell personal information that users have not posted on our services. In some cases, user’s email provider can upload information when communicating with us, which is out of our control and it’s in the control of all users with their provider to manage all data and compliance. In other words, users will be granting their third party provider the right to store information, disclose and provide access to the contact whichh they’re communicating and should be within the third party’s terms of agreement and privacy policy.

For any feedback information, we may want to expose to third party, such as future partner, suppliers or any organisation for the benefit of our business. This may include with parties from governing bodies such as Financial Conduct Authority or any organisation who monitor for the protection of all users and potential new customers security.

Our supplier, partner and current team are in connection with Athlete Finders  for building, maintaining, monitoring, security, or any other service provider such as google analytics will have some sort of links or resources to any of our application. For example, we upload all our data management files, our current infrastructure is built on AWS platform as it’s the most reliable for security, effective and cost efficient for all users and Athlete Finders . The key thing that we protect our user is that we restrict all our team, partner and supplier to have access to copy or infringe any of our data as we have our own agreement in place to protect the company Athlete Finders  and for the best experience of our users.

2.3.	Players, Videos, Event and Club/Organisation Search Options
We provide three content; Video Search page, Profile Search page, Group/Company page and Event Search page. Within these contents, all features and contents can be filtered by categories which helps filter the options so users can seek for what they want. Each user would have uploaded information on their profile, videos, group and events so that it can be searched easily by data, time, location, video contents, user’s name, age, gender, status, role, location and many more.

2.4.1 Stats Analysis & data on individual and team performance
You or any member from your club / company (the coach or any management team from the organisation) may pay us or request a free trial to do a full stats analysis of your team and individual for improving performance. Each action we mark may tag you that are related to the successful or failed stats to the best of our knowledge and ability to spot based on the quality of the video and the information given to us. We may received the squad number, images of each members, and position.

It is possible we can make human error however, each action will have a video replay button to watch the action which helps you to share the URL and tell us the correction by contacting us admin@athletefinders.com. This can be prevented and improved if we have the same analyst doing the project for the same team where he/she will be familiarised who is who on the video as everyone must understand, sometime it isn’t easy to identify from poor quality of video and if there are players who look identical.

All data of individual performance is exposed to all members as the purpose is to help all players and coaches improve individual and team performance and at the same time, individual can be recognised by scouts and agents who use the data to make better decision. All user can make their profile private so no one can view the stats except their followers (people they have connected with on the platform). To hide team performance, the club profile will also need to be private and all members have access to view. For example, your club may have created a club profile where all the players have joined the club. When stats analysis has been completed, all members can see overall team performance or filter the data based on player by player etc. This mean the coach and all members will be able to see the data.

You can contact us on how to make the profile account private or speak with you account manager.

By you joining this platform, you understand that the stats analysis is the benefit of you and your team to improve, measure, make better decision but our long term mission is to help some of talents like yourself to improve and move up the ladder and achieve your dream but if you don’t agree and are not happy, we suggest you close your account down. If your club had requested to use our service without your knowledge, please contact us and if you wish to remove your profile, we will be happy to do this after a proof of ID and personal contact is shared to us. We will also speak to the person who requested to use our service as they may have paid or used the free trial which they need to confirm you are the actual person.

2.4.	Disclosure for all Legal Process and Compliance
If necessary to enforce our user agreement due to circumstance such as illegal, misuse, or abusive, we may have to disclose user’s personal information as it could be compelled by the law, subpoena or any other legal process.

When we state that we may disclose user’s information, this includes all information such as profile (members and visitors), all activities from registration and onwards and anything within Athlete Finders  service which could be shared to UK, Ireland, United States or any other jurisdiction.

In other situations it could be; (1) investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce the User Agreement, investigate and defend ourselves against any third-party claims or allegations, or protect the security or integrity of our Service; or (3) exercise or protect the rights, property, or safety of Athlete Finders , our Members, personnel, or others. We attempt to notify Members about legal demands for their personal information when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. In light of our principles, we may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but do not commit to challenge every demand.

2.5.	Change of owners, Control or Sale of our asset
In the unlikely situation, there may come a time which there could be investors, new owners, partners and affiliates due to sales or transfer, that we may share your information with a third party, who will have the right to use that information in line with this Privacy Policy. What we mean is that it could be a potential buyer of Athlete Finders .

2.7 Data Processing Outside Your Country
Currently we’re based in UK however as we grow and collaborate with new department, we may process users information outside of UK to where users live so that we can operate efficiently and effectively.

3.	User’s control, rights and obligation
User can control their restrictions and manage their account with the tools provided on the platform however if they are not satisfied with any of the agreement, policy and features available on our services, the user can close the account at any time. Users have the ability to add, remove and edit on each of their post, videos, and events to communicate on the platform. Nevertheless, user can contact us via the contact page for any additional information.

If any other member (for example User X) who has the URL link of another user’s (User Y) profile page posted on an external site, which later User Y deleted their account, the URL link won’t have access as it will state there’s no source but if members have taken information at the time the account was active, or taken screen shot, we can’t do nothing about that.

3.1.	Data Retention
If a user deletes their account, all data will be deleted automatically and instantly which if something occurs in the future, we may not be able to retrieve any data. We would like to build a system to be able to retrieve old data, just for the security and protect all users with a safe environment and experience to resolve disputes, abuse, prevent fraud or any illegal activities, or even enforce the policies and agreement however if and when we do, we will update and notify all users.

4.	Important Information
By continuing to use our service, all users agree that they agree with our policy, terms of service and any other agreements added, replace or any changes. It’s the user’s responsibility to read through all policies and agreement.

All agreement will be governed and enforced under the jurisdiction of English Law and all activities undertaken by Athlete Finders  is in accordance to The Data Protection Act 1998. User’s information will be shared with all members on the platform to help increase their chances of success and any other business activities we intend to increase more opportunities however we will not sell or go against our commitment to protect our users.

• The processing is necessary in order to protect the vital interests of the data subject.
• The data subject has given his explicit consent to the processing of the personal data.

4.1.	Security
All users must be aware that internet is not 100% secured environment however we have enabled HTTPS and SSL for our site and mobile devices.

We advised that all users create a strong password and keep it confidential to not share with anyone. On the other side, we monitor our services to be alert and prevent any attacks and possible vulnerabilities but we can’t ensure or warrant the security of information that you share to us. This is why we advise users to do the best they can to prevent any fraud or illegal activities and not exchange any information on our services, email or any other sites etc.

If any information which are exposed, there is no guarantee that they can’t be disclosed, altered, accessed or destroyed by any of our technical, physical or managerial safeguard.</p>
                {/* <p className='text-gray-400 font-semibold'>Last revised on 3rd March, 2019</p>

                <p className='text-lg text-gray-400'>We are a performance analysis company for athletes and all football enthusiast within sport organisations to connect, share, engage, seek opportunities, streamline all current processes and recruit for all amateurs and professionals around the world.</p>

                <p className='mt-4'>Our short-term objective is to help players, coaches and clubs to improve their performance with stats and video replay within our software and the long-term vision is to expose players and coaches directly to scouts, agents or anyone within our network to help them get recruited with facts, and no based on bias or favouritism. We want to increase the chances of players and coaches to be judged fairly and help scouts and agents to make better decision with our stats and video replay</p>
                <br />
                <p>We are committed to protecting our member’s personal information when using our services by the industry standard safeguards. All members have the capability to make their profile private and hide their performance analysis and other key profile information.</p>
                <br />
                <p>If there is any changes to our privacy policy, we will always provide updates as according to the terms of service section 5 (Notices and Services messages) however, we may share user’s information with their consent or as required by the law however, if the profile is visible, we will share performance analysis of the user to help raise awareness of their talent to get scouted.</p>
                <br/>
                <p>In order to maintain trust and confidence from our members, we are disciplined and committed to follow the guideline in the following to protect member’s privacy.</p>
                <p className='mb-2'>If we’re to provide information to third parties:</p>

                <p>a. We will get users consent</p>
                <br/>
                <p>b. If necessary to follow user’s instruction</p>
                <br/>
                <p>c. With reasonably necessary to provide our features and functionality to usersWe will get users consent</p>
                <br/>
                <p>d. If we are required by law with reasonable, subpoena or other legal process</p>
                <br/>
                <p>e. If it’s necessary to enforce our terms of service or protect all properties, rights and safety of all members, visitors and the public.We will get users consent</p>
                <br/> */}
            </div>
            <Footer user={user} setShowModal={setShowModal}/>

        </div>
    )
}

export default Privacy
