import React from 'react';
import { useNavigate } from 'react-router-dom'


const FeatureGrid = () => {
    const nav=useNavigate();

    const features = [
        {
            title: 'Players',
            description:
                'Analyse unknown football players and scout them for agents',
            link: '/About',
        },
        {
            title: 'Analysts',
            description:
                'Give access to beginners to learn fast and analyse players & teams',
                link: '/About',
            },
        {
            title: 'Agents',
            description:
                'Analyse their players and provide database of top talents with data & video analysis.',
                link: '/About',
            },
    ];

    return (
        <div className="bg-white py-16">
            <h2 className='text-center font-semibold text-lg mb-5'>What do we do and for who?</h2>
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                {features.map((feature, index) => (
                    <div
                        key={index}
                        className="bg-white p-6 items-center block justify-center"
                    >
                        <h3 className="text-xl font-semibold mb-2 text-center">{feature.title}</h3>
                        <p className="text-gray-700 mb-4 text-center text-sm">{feature.description}</p>
                        <p onClick={() => nav('/About')} className='text-center bg-blue-700 p-1 text-white rounded-md cursor-pointer'>Learn More</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeatureGrid;